import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import {importSPKI, jwtVerify} from "jose";
import Cookies from 'js-cookie';

const api = axios.create();

api.interceptors.request.use(config => {
  const jwt = Cookies.get('SESSID');
  if (jwt) {
    const payload = jwtDecode(jwt);
    config.baseURL = payload.url_admin;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export const checkJwtValidity = async () => {
  const jwtToken = Cookies.get('SESSID');
  if (!jwtToken) return false;

  try {
    const payload = jwtDecode(jwtToken);
    const response = await api.get('/api/v2/auth/key');
    const key = response.data.data.key;
    if (!key) {
      return false;
    }
    const publicKey = await importSPKI(key, payload.algorithm);
    await jwtVerify(jwtToken, publicKey, {algorithms: [payload.algorithm]});
    return true;
  } catch(e) {
    return false;
  }
}