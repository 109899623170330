import React from 'react';
import './Unauthorized.css';

const Unauthorized = () => {
    return (
        <div className="unauthorized-container">
            <h1>Acesso Negado</h1>
            <p>Por favor acesse pelo menu no seu painel!</p>
        </div>
    );
};

export default Unauthorized;