import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import SelectScreen from "./pages/SelectScreen";
import Home from "./pages/Home/Home";
import Construcao from "./pages/Construcao/Construcao";
import Joias from "./pages/Joias/Joias";
import Estrategico from "./pages/Estrategico/Estrategico";
import Moda from "./pages/Moda/Moda";
import Moveis from "./pages/Moveis/Moveis";
import Onboarding from "./pages/Onboarding/Onboarding";
import Faturamento from "./pages/Faturamento/Faturamento";
import Lancamento from "./pages/Lancamento/lancamento";
import Front from "./pages/Front/Front";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import NotAuthorized from "./pages/NotAuthotized/Unauthorized";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const checkWindowSize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    checkWindowSize();

    window.addEventListener("resize", checkWindowSize);

    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('is_admin');
      sessionStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
      <Router>
        <div>
          {isMobile && (
              <div id="info-div">
                <div className="container-infodiv">
                  <p>
                    Este conteúdo não está disponível em dispositivos com largura de tela inferior a 1200px.
                  </p>
                </div>
              </div>
          )}
          <div className={isMobile ? "inaccessible" : ""}>
            <Routes>
              <Route path="/" element={<ProtectedRoute element={Home} />} />
              <Route path="/select" element={<ProtectedRoute element={SelectScreen} onlyAuth={true} />} />
              <Route path="/construcao" element={<ProtectedRoute element={Construcao} onlyAuth={true} />} />
              <Route path="/joias" element={<ProtectedRoute element={Joias} onlyAuth={true} />} />
              <Route path="/estrategico" element={<ProtectedRoute element={Estrategico} onlyAuth={true} />} />
              <Route path="/moda" element={<ProtectedRoute element={Moda} onlyAuth={true} />} />
              <Route path="/moveis" element={<ProtectedRoute element={Moveis} onlyAuth={true} />} />
              <Route path="/onboarding" element={<ProtectedRoute element={Onboarding}/>} />
              <Route path="/front" element={<ProtectedRoute element={Front} onlyAuth={true} />} />
              <Route path="/faturamento" element={<ProtectedRoute element={Faturamento} onlyAuth={true} />} />
              <Route path="/lancamento" element={<ProtectedRoute element={Lancamento} onlyAuth={true} />} />
              <Route path="*" element={<NotAuthorized />} />
            </Routes>
          </div>
        </div>
      </Router>
  );
}

export default App;
