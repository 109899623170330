import React, { useEffect, useState } from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {checkJwtValidity} from "../../services/api";
import Cookies from "js-cookie";
import Unauthorized from "../../pages/NotAuthotized/Unauthorized";
import { Loading } from '@falcon-ds/loading'

const ProtectedRoute = ({ element: Component, onlyAuth = false, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const location = useLocation();
  useEffect(() => {
    if (token) {
      Cookies.set('SESSID', token, { expires: 1 / 12 });
      searchParams.delete('token');
      setSearchParams(searchParams);
    }
  }, [token]);

  useEffect( () => {
    const validateJwt = async () => {
      setLoading(true);
      const jwt = Cookies.get('SESSID');
      if (jwt) {
        if(await checkJwtValidity()){
          setIsAuthenticated(true);
          return;
        }
      }
      setIsAuthenticated(false);
    }
    validateJwt()
    setLoading(false);
  }, [location]);

  if (loading) {
    return <Loading/>;
  }

  if (!isAuthenticated && onlyAuth){
    return <Unauthorized/>
  }

  return <Component {...rest} isAuthenticated={isAuthenticated}/>;
};

export default ProtectedRoute;
