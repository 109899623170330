import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import VideoPlayer from "../../components/VideoPlayer";
import Quiz from "../../components/Quiz/Quiz";
import Checklist from "../../components/Checklist/Checklist";
import HomeIcon from "../../components/HomeIcon/HomeIcon";
import Certificate from "../../components/Certificate/Certificate"
import './Moda.css'

const videoSections = {
  sections: [
    {
      title: "Pré Cadastro",
      videos: [
        {
          id: 1,
          title: "1 - Criando Categorias de Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/1-pre-cadastro-categorias.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 2,
          title: "2 - Criando Características",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/2-pre-cadastro-caracteristicas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 3,
          title: "3 - Criando Marcas",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/3-pre-cadastro-marcas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 4,
          title: "4 - O que são Derivações?",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/4-pre-cadastro-derivacoes.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 5,
          title: "Quiz",
          url: "",
          showQuiz: true,
          quizId: 1,
          quiz: {
            questions: [
              {
                question: "Em um produto que tenho do mesmo modelo, diferentes cores, é correto afirmar que ele será derivado?",
                answers: [
                  "A - Como um produto único, sem derivação",
                  "B - Com derivação de cor",
                  "C - Nenhuma das anteriores, não preciso usar derivações no meu produto",
                  "D - Com derivação apenas de tamanho, mencionando a cor no nome do produto",
                ],
                correct: 3,
                reasons: [
                  "A questão está incorreta! Produtos com diferentes cores e tamanhos nessessariamente precisam conter alguma derivação para que fique mais facil a compra aos olhos do cliente final",
                  "A questão está incorreta! Usar apenas a derivação de cor, fará com que o seu produto fique sem a opção de escolha de tamanho, por isso, deve-se escolher com cuidado qual derivação será usada",
                  "A questão está incorreta!",
                  "Parabéns, você acertou. Podemos estar derivando por tamanho e inserindo a cor no nome do produto, para que posteriormente tenhamos mais facilidade com marketing e outras opções com o cadastro",
                ],
              },
            ],
          },
        },
        {
          id: 6,
          title: "Faça você mesmo",
          url: "",
          showQuiz: false,
          showChecklist: true,
          checklistTitle: "Agora que finalizou os videos, chegou a hora de colocar a mão na massa. Realize as tarefas abaixo usando tudo o que aprendeu",
          checklist: {
            items: ["Crie Categorias", "Crie Características", "Crie Marca", "Compreender Derivações"],
          },
        },
      ],
    },
    {
      title: "Cadastro de Produto",
      videos: [
        {
          id: 7,
          title: "1 - Base",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/5-cadastro-step-1-base.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 8,
          title: "2 - Tributação e Dimensão",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/6-cadastro-step-2-tributacao%20e%20dimensao.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 9,
          title: "3 - Adicionando Derivações",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/7-cadastro-step-3-derivaçoes.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 10,
          title: "4 - Configuração de Mídias",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/8-cadastro-step-4-midias.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 11,
          title: "5 - Adicionando Características",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/9-cadastro-step-5-caracteristicas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 12,
          title: "6 - Descrição Geral",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/10-cadastro-step-6-descricao-geral.mp4",
          showQuiz: false,
          showChecklist: false,
        },

        {
          id: 13,
          title: "7 - SEO",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/11-cadastro-step-7-seo.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 14,
          title: "8 - Inserindo Preço no Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/12-cadastro-step-8-configuracao-final.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 15,
          title: "13 - Adicionando Estoque no Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/13-cadastro-step-9-estoque.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 16,
          title: "13 - Como Duplicar Cadastros",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Moda/14-cadastro-step-10-duplicando-cadastros.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 17,
          title: "Quiz",
          url: "",
          showQuiz: true,
          quizId: 1,
          quiz: {
            questions: [
              {
                question: "Considerando que o produto sugerido tem a função de relacionar produtos iguais de cores diferentes, qual a importância de fazer o produto sugerido",
                answers: [
                  "A - Usar o produto sugerido, faz eu ter mais produtos no site;",
                  "B - O produto sugerido deixa a parte da página de produto mais bonita;",
                  "C - Usando o produto sugerido, consigo ter meus produtos em URL separadas, fazendo mesmo assim eles não perderem o vinculo entre eles na página de cada produto, além de me permitir trabalhar melhor com campanhas, parte de SEO, toda parte de performance dos produtos individualmente, e também deixando visualmente mais bem-posto as derivação que ficam com a própria imagem do produto no lugar das cores solidas;",
                  "D - Usando o produto sugerido, consigo ter meus produtos todos juntos, fazendo mesmo assim eles perderem o vinculo entre si na página de cada produto, além de me permitir trabalhar melhor com campanhas, parte de SEO, toda parte de performance dos produtos individualmente, e também deixando visualmente mais bem-posto as derivação que ficam com a própria imagem do produto no lugar das cores solidas;",
                ],
                correct: 2,
                reasons: [
                  "A questão está incorreta! O produto sugerido não necessariamente vai fazer você ter mais produtos no site, mas sim ter mais opções para os seus clientes, também facilitando e ajudando no marketing do seu sites",
                  "A questão está incorreta! Aleḿ de deixar a pagina do produto bem mais bonita, temos algumas outras vantagens relacionadas a venda do produto e rankeamento do google, é importante que tenhamos isso em mente para que possamos trabalhar da forma correta.",
                  "Parabéns, você acertou!! O produto sugerido pode ser um parceiro implacavél na hora de fazer marketing para os seus produtos, além de deixar a sua pagina de produto muito mais estilizada, ajudando também na confiança do cliente na hora de comprar o seu produto",
                  "A questão está incorreta! Os produtos não perdem o vinculo quando usamos produtos sugeridos, ",
                ],
              },
            ],
          },
        },
        {
          id: 18,
          title: "Faça Você Mesmo",
          url: "",
          showQuiz: false,
          showChecklist: true,
          checklistTitle: "Faça o cadastro de dois produtos seguindo o checklist abaixo.",
          checklist: {
            items: ["Faça o cadastro de um produto", "Insira preço no produto", "Insira estoque no produto", "Duplique esse produto com outra cor"],
          },
        },
        {
          id : 19,
          title: 'Conclusão do Módulo',
          certificate: true,
        }
      ],
    },
  ],
};

function Moda() {
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(1);
  const [activeSection, setActiveSection] = useState(0);
  const [quizActive, setQuizActive] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizReasons, setQuizReasons] = useState([]);
  const [checklistActive, setChecklistActive] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistTitle, setChecklistTitle] = useState("");
  const [certificateActive, setCertificateActive] = useState(false);

  const handleVideoSelect = (
    videoUrl,
    videoId,
    showQuiz,
    showChecklist,
    checklist,
    checklistTitle,
    certificate
  ) => {
    if (currentVideoId === videoId) {
      return;
    }

    setSelectedVideoUrl(videoUrl);
    setCurrentVideoId(videoId);

    if (showQuiz) {
      if (!quizActive) {
        setQuizActive(true);
        setChecklistActive(false);
        setCertificateActive(false);
        const selectedVideo = videoSections.sections
          .flatMap((section) => section.videos)
          .find((video) => video.id === videoId);

        if (selectedVideo && selectedVideo.quiz) {
          setQuizQuestions(selectedVideo.quiz.questions);
          setQuizReasons(
            selectedVideo.quiz.questions.map((question) => question.reasons)
          );
        } else {
          setQuizQuestions([]);
          setQuizReasons([]);
        }
      }
    } else if (showChecklist) {
      if (!checklistActive) {
        setChecklistActive(true);
        setQuizActive(false);
        setCertificateActive(false);
        setChecklistItems(checklist.items);
        setChecklistTitle(checklistTitle);
      }
    } else if (certificate) {
      setCertificateActive(true);
      setQuizActive(false);
      setChecklistActive(false);
    } else {
      setQuizActive(false);
      setChecklistActive(false);
      setCertificateActive(false);
      setQuizQuestions([]);
      setQuizReasons([]);
      setChecklistItems([]);
      setChecklistTitle("");
    }
  };

  const handleNextVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex < currentSection.videos.length - 1) {
      const nextVideo = currentSection.videos[currentVideoIndex + 1];
      handleVideoSelect(
        nextVideo.url,
        nextVideo.id,
        nextVideo.showQuiz,
        nextVideo.showChecklist,
        nextVideo.checklist,
        nextVideo.checklistTitle,
        nextVideo.certificate
      );
    } else if (currentSectionIndex < videoSections.sections.length - 1) {
      const nextSection = videoSections.sections[currentSectionIndex + 1];
      const firstVideoOfNextSection = nextSection.videos[0];
      handleVideoSelect(
        firstVideoOfNextSection.url,
        firstVideoOfNextSection.id,
        firstVideoOfNextSection.showQuiz,
        firstVideoOfNextSection.showChecklist,
        firstVideoOfNextSection.checklist,
        firstVideoOfNextSection.checklistTitle,
        firstVideoOfNextSection.certificate
      );
      setActiveSection(currentSectionIndex + 1);
    }
  };

  const handlePreviousVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex > 0) {
      const previousVideo = currentSection.videos[currentVideoIndex - 1];
      handleVideoSelect(
        previousVideo.url,
        previousVideo.id,
        previousVideo.showQuiz,
        previousVideo.showChecklist,
        previousVideo.checklist,
        previousVideo.checklistTitle,
        previousVideo.certificate
      );
    } else if (currentSectionIndex > 0) {
      const previousSection = videoSections.sections[currentSectionIndex - 1];
      const lastVideoOfPreviousSection =
        previousSection.videos[previousSection.videos.length - 1];
      handleVideoSelect(
        lastVideoOfPreviousSection.url,
        lastVideoOfPreviousSection.id,
        lastVideoOfPreviousSection.showQuiz,
        lastVideoOfPreviousSection.showChecklist,
        lastVideoOfPreviousSection.checklist,
        lastVideoOfPreviousSection.checklistTitle,
        lastVideoOfPreviousSection.certificate
      );
      setActiveSection(currentSectionIndex - 1);
    }
  };

  const handleQuizComplete = () => {
    setQuizActive(false);
    handleNextVideo();
  };

  const handleChecklistComplete = () => {
    setChecklistActive(false);
    handleNextVideo();
  };

  useEffect(() => {
    const currentVideo = videoSections.sections
      .flatMap((section) => section.videos)
      .find((video) => video.id === currentVideoId);
    if (currentVideo) {
      setSelectedVideoUrl(currentVideo.url);
      setQuizActive(currentVideo.showQuiz);
      setChecklistActive(currentVideo.showChecklist);
      setCertificateActive(currentVideo.certificate === true);
      if (currentVideo.checklist) {
        setChecklistItems(currentVideo.checklist.items);
        setChecklistTitle(currentVideo.checklistTitle);
      } else {
        setChecklistItems([]);
        setChecklistTitle("");
      }
      setQuizQuestions(currentVideo.quiz ? currentVideo.quiz.questions : []);
      setQuizReasons(
        currentVideo.quiz
          ? currentVideo.quiz.questions.map((question) => question.reasons)
          : []
      );
    }
  }, [currentVideoId]);

  return (
    <div className="container-cp">
      <HomeIcon />
      <Sidebar
        sections={videoSections.sections}
        onVideoSelect={handleVideoSelect}
        currentVideoId={currentVideoId}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />

      {quizActive ? (
        <Quiz
          questions={quizQuestions}
          reasons={quizReasons}
          onQuizComplete={handleQuizComplete}
        />
      ) : checklistActive ? (
        <Checklist
          title={checklistTitle}
          items={checklistItems}
          onChecklistComplete={handleChecklistComplete}
        />
      ) : certificateActive ? (
        <Certificate moduleName="Cadastro de Produto" />
      ) : (
        <VideoPlayer
          videoUrl={selectedVideoUrl}
          onNextClick={handleNextVideo}
          onBackClick={handlePreviousVideo}
        />
      )}

    </div>
  );
}

export default Moda;